<template>
  <div>
    <v-container>
      <!-- 消息条 -->
      <v-snackbar :timeout="1500" class="mt-4" v-model="notiBar.enabled" top transition="slide-y-transition"
        :color="notiBar.color" center text style="top: 56px">
        {{ notiBar.text }}
      </v-snackbar>
      <!-- 客户端列表区域 -->
      <v-card class="my-3">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 pl-0"><v-btn depressed to="/"
              class="mr-5"><v-icon>mdi-arrow-left-thick</v-icon>返回</v-btn>日志聚合平台
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text>
          <!-- 筛选条件 -->
          <v-row>
            <v-col>
              <v-text-field class="mx-2" v-model="queryClientID" label="检索客户端" single-line hide-details></v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="mx-2" v-model="queryMsg" label="检索日志内容" single-line hide-details></v-text-field>
              <v-checkbox v-model="queryMsgBin" label="包括二进制"></v-checkbox>
            </v-col>
            <v-col>
              <v-text-field class="mx-2" v-model="queryNum" label="拉取数量" single-line hide-details></v-text-field>
            </v-col>
            <v-col>
              <v-select class="mx-2" v-model="log_type_select" :items="log_types_list" label="日志类型"></v-select>
            </v-col>
          </v-row>
          <!-- 展示客户端 -->
          <div class="d-flex flex-wrap my-5">
            <v-btn rounded depressed color="primary" small class="ma-1" :disabled="queryClientID == ''"
              @click="queryClientID = ''">清除</v-btn>

            <v-btn v-for="(item, i) in clients" rounded depressed small class="ma-1" :key="i"
              @click="queryClientID = item.client_id"><v-badge color="primary" :content="item.log_num">
                {{ item.name }}
              </v-badge> </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto pa-5 mt-5">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 pl-0">日志列表
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="搜索日志内容" single-line
            hide-details></v-text-field>
          <v-btn color="" icon @click="refresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-app-bar>
        <!-- 展示level统计 -->
        <v-card-text>
          <div class="d-flex flex-wrap">
            <v-btn rounded depressed color="primary" small class="ma-1" :disabled="search == ''"
              @click="search = ''">清除</v-btn>

            <v-btn rounded depressed small class="ma-1 grey " @click="search = 'Debug'">
              <v-badge class="white--text" color="primary" v-if="level_stats.debug != 0" :content="level_stats.debug">
                Debug
              </v-badge>
              <div class="white--text" v-else>Debug</div>
            </v-btn>

            <v-btn rounded depressed small class="ma-1 teal " @click="search = 'Info'">
              <v-badge class="white--text" color="primary" v-if="level_stats.info != 0" :content="level_stats.info">
                Info
              </v-badge>
              <div class="white--text" v-else>Info</div>
            </v-btn>

            <v-btn rounded depressed small class="ma-1 amber lighten-1" @click="search = 'Warn'">
              <v-badge class="white--text" color="primary" v-if="level_stats.warn != 0" :content="level_stats.warn">
                Warn
              </v-badge>
              <div class="white--text" v-else>Warn</div>
            </v-btn>

            <v-btn rounded depressed small class="ma-1 deep-orange lighten-1" @click="search = 'Error'">
              <v-badge class="white--text" color="primary" v-if="level_stats.error != 0" :content="level_stats.error">
                Error
              </v-badge>
              <div class="white--text" v-else>Error</div>
            </v-btn>

            <v-btn rounded depressed small class="ma-1 deep-purple darken-4" @click="search = 'Fatal'">
              <v-badge class="white--text" color="primary" v-if="level_stats.fatal != 0" :content="level_stats.fatal">
                Fatal
              </v-badge>
              <div class="white--text" v-else>Fatal</div>
            </v-btn>

          </div>
        </v-card-text>
        <!-- 展示level统计 -->

        <!-- 表格区 -->
        <v-card-text>
          <!-- <div class="d-flex flex-wrap">
            <v-btn rounded depressed color="primary" small class="ma-1" :disabled="search == ''"
              @click="search = ''">清除</v-btn>
          </div> -->
          <v-data-table :headers="headers" :items="list" :search="search" :items-per-page="100" :loading="loading"
            loading-text="正在获取数据。。。" :calculate-widths="false">
            <!-- level的展示 -->
            <template v-slot:item.level="{ item }">
              <v-chip :color="getColor(item.level)" dark>
                {{ item.level }}
              </v-chip>
            </template>
            <!-- level的展示 -->
          </v-data-table>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>

      <!-- 提示框 -->
      <v-dialog v-model="alert.alert" max-width="450px">
        <v-card>
          <v-card-title>{{ alert.alert_type }}</v-card-title>
          <v-card-text>
            {{ alert.alert_text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="alert.alert = !alert.alert">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "adminLogs",
  data: function () {
    return {
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "客户端", value: "client_id" },
        { text: "汇报时间", value: "time" },
        { text: "等级", value: "level" },
        { text: "内容", value: "content" },
        { text: "调用信息", value: "caller" },
      ],
      list: [],
      clients: [],
      level_stats: [],
      log_type_select: "all",
      log_types_list: ["all", "debug", "warning", "info", "error", "fatal"],
      queryClientID: "",
      queryMsg: "",
      queryMsgBin: false,
      queryNum: 300,
      realtime_update_id: -1,
      loading: false,
      realtime_exit: false,
      realtime_id: 0,
      realtime_last_ts: 0,//上次查询接口的时间戳，防止频繁访问
      alert: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      notiBar: {
        enabled: false,
        color: "",
        text: "",
      },
    };
  },
  mounted: function () {
    this.initData();

    //实时检测
    setTimeout(() => {
      this.realtime_id = this.randem_num();
      this.realtime_check();
    }, 500);
  },
  watch: {
    queryClientID: function () {
      this.initData();
    },
    queryNum: function () {
      this.initData();
    },
    log_type_select: function () {
      this.initData();
    },
    queryMsg: function () {
      this.initData();
    },
    queryMsgBin:function () {
      this.initData();
    },
  },
  beforeDestroy() {
    // 清理数据和事件监听器
    this.realtime_exit = true;
  },
  methods: {
    initData: function () {
      this.loading = true;
      //获取数据
      this.axios
        .get("/api/admin/listLogs",
        {
          params: {
            client:this.queryClientID,
            msg:this.queryMsg,
            msg_bin:this.queryMsgBin,
            num:this.queryNum,
            type:this.log_type_select,
          }
        }  
        )
        .then((res) => {
          if (res.data.status === 0) {
            // console.log(res.data.data.stats);
            this.level_stats = res.data.data.stats;
            this.clients = res.data.data.clients;
            this.list = res.data.data.list;
            this.realtime_update_id = res.data.data.realtime_update_id;
          } else {
            this.errorBar(this.parseError(res));
          }
          this.loading = false;
          return;
        })
        .catch((err) => {
          console.log(err);
          this.error("发生错误:" + err);
        });
    },
    realtime_check: function () {
      //var job_id = this.realtime_id;
      if (this.realtime_exit == true) {
        //console.log("this.realtime_exit == true");
        return;
      }
      // if(this.realtime_id!= job_id){
      //   console.log("this.realtime_id!= job_id");
      //   return;
      // }

      if (this.realtime_last_ts == 0) {
        this.realtime_last_ts = new Date().getTime();
      } else {
        var now_ts = new Date().getTime();
        if (now_ts - this.realtime_last_ts <= 1000) {
          console.log("频繁访问，已结束递归调用");
          this.error("已暂停自动更新");
          return;
        }
      }


      //获取数据
      this.axios
        .get("/api/admin/listLogsUpdate?id=" + this.realtime_update_id, {
        })
        .then((res) => {
          if (res.data.status === 0) {
            //skip
            //console.log("无更新");
          } else if (res.data.status === 1) {
            //检测到更新
            this.initData();
          } else {
            this.errorBar(this.parseError(res));
          }
          this.realtime_check();
          return;
        })
        .catch((err) => {
          console.log(err);
          //this.realtime_exit = true;
          this.error("发生错误:" + err);
        });

    },
    randem_num() {
      return Math.round(Math.random() * 999);
    },
    refresh: function () {
      this.initData();
      this.successBar("刷新成功");
    },
    getColor: function (level) {
      switch (level) {
        case "Debug":
          return "grey";
        case "Info":
          return "teal";
        case "Warn":
          return "amber lighten-1";
        case "Error":
          return "deep-orange lighten-1";
        case "Fatal":
          return "deep-purple darken-4";
      }
    },
    success: function (text) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = text;
      this.alert.alert_type = "成功";
    },
    error: function (err) {
      this.alert.alert = !this.alert.alert;
      this.alert.alert_text = err;
      this.alert.alert_type = "错误";
    },
    successBar: function (text) {
      this.notiBar.enabled = !this.notiBar.enabled;
      this.notiBar.text = text;
      this.notiBar.color = "success";
    },
    errorBar: function (err) {
      this.notiBar.enabled = !this.notiBar.enabled;
      this.notiBar.text = err;
      this.notiBar.color = "error";
    },
  },
};
</script>

<style></style>
